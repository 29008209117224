import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import Layout from '../../components/Layout';
import HomeBanner from '../../components/HomeBanner';
import Typography from '../../components/Typography';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import CardBody from '../../components/CardBody';
import Icon from '../../components/Icon';

SwiperCore.use([Navigation]);

const HomeTemplate = ({ seo, banner, location, media }) => (
  <Layout location={location} headerOptions={{ variant: 'fixed-top', bgColor: 'transparent', color: 'dark' }}>
    <Helmet
      title={seo?.title}
      meta={[
        {
          name: 'description',
          content: seo?.description,
        },
      ]}
    />
    <HomeBanner title={banner?.title} text={banner?.text} />

    <Grid container>
      <hr className="my-0 dashed" />
    </Grid>

    <Grid container>
      <Typography variant="h2" className="mb-56 mt-72" align="center">
        {media?.title}
      </Typography>
      <Grid row spacing={32} justify="between" align="center">
        {media?.logos?.map(logo => (
          <Grid column xs={6} md={4} lg="auto" key={logo?.title}>
            <GatsbyImage alt={logo?.title} image={logo?.image?.childImageSharp?.gatsbyImageData} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Layout>
);

HomeTemplate.propTypes = {
  seo: PropTypes.object,
  banner: PropTypes.object,
  location: PropTypes.object,
  media: PropTypes.object,
};

HomeTemplate.defaultProps = {
  seo: {},
  banner: undefined,
  location: undefined,
  media: {},
};

export default HomeTemplate;
